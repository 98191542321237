export const handleError = (app, error) => {
  let errorMessage = error;

  if (error.response) {
    errorMessage = error.response.data.message;
  } else if (error.message) {
    errorMessage = error.message;
  }

  app.$notify({
    type: "error",
    title: "Error",
    text: errorMessage
  });
};

export const handleSuccess = (app, res) => {
  app.$notify({
    type: "success",
    title: "Success",
    text: res.data.message || "Success"
  });
};
