<template>
    <div class="no-internet-dialog">
        <div class="text-center">
            <h1>Unable to connect to the internet</h1>
            <p class="no-internet-dialog__info">
                You are currently not connected to the internet
            </p>
            <div class="image-container mt-4">
                <img
                    src="../../assets/icons/nointernet1.svg"
                    class="img1"
                    alt=""
                />
                <br />
                <img
                    src="../../assets/icons/nointernet2.svg"
                    class="img2"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@use "../../assets/scss/mixins/index" as *;

.no-internet-dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px;
}

.no-internet-dialog__info {
    color: #555555;
}

.image-container {
    position: relative;
    display: inline-block;
}

.img1 {
    position: absolute;
    top: -18px;
    right: -5px;
}

.no-internet-dialog h1 {
    @include query("mobile") {
        font-size: 20px;
    }
}

.no-internet-dialog__info {
    @include query("mobile") {
        font-size: 13px;
    }
}
</style>
