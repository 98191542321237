<template>
  <portal :to="target">
    <div
      :class="`u-fixed modal__wrapper ${!value ? 'modal__wrapper-closed' : ''}`"
      ref="wrapper"
    >
      <div
        @click="closeModal"
        :class="`modal__overlay u-fixed ${
          !value ? 'modal__overlay-closed' : ''
        }`"
      ></div>

      <div
        :class="[
          'u-relative',
          'modal__body',
          `${!value ? 'modal__body-closed' : ''}`,
          `modal__body-${position}`,
          fullMobileWidth ? 'full__mobile-width' : '',
          size ? `modal__body-${size}` : ''
        ]"
      >
        <slot></slot>
        <button
          v-if="showCloseIcon"
          @click="closeModal"
          class="modal__close-icon cursor-pointer"
        >
          <img src="../assets/icons/close-icon.svg" alt="close-icon" />
        </button>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: "Modal",
  props: {
    position: {
      type: String,
      default: "center"
    },
    showCloseIcon: {
      type: Boolean,
      default: true
    },
    fullMobileWidth: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    },
    value: {
      default: false
    },
    target: {
      type: String,
      default: "modal-portal"
    }
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("input", false);
    }
  },
  watch: {},
  mounted() {}
};
</script>

<style scoped lang="scss">
// @use "../assets/scss/mixins/index" as *;
@use "../assets/scss/mixins/index.scss" as *;

.modal {
  &__wrapper {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    top: 0;
    left: 0;
    z-index: 3000;

    &-closed {
      pointer-events: none;
    }
  }
  &__overlay {
    width: 100%;
    background: #14141494;
    height: 100%;
    transition: 0.2s all ease-out;

    &-closed {
      opacity: 0;
    }
  }

  &__body {
    background: #fff;
    padding: 3.2rem 5.6rem;
    width: 560px;
    border-radius: 4px;
    transition: 0.3s all cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    max-height: 99vh;
    overflow-y: auto;

    @include query("mobile") {
      width: 320px;
      padding: 2.4rem;

      &.full__mobile-width {
        width: 96%;
      }
    }

    &-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
    }

    &-top {
      margin: 63px 0;
      left: 50%;
      transform: translateX(-50%) scale(1);
      border-radius: 10px;
    }

    &-closed {
      &.modal__body-center {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.3);
      }
      &.modal__body-top {
        opacity: 0;
        transform: translateX(-50%) scale(1.3);
      }
    }

    &-wide {
      width: 62%;
      padding: 53px 93px;
    }

    @include query("mobile") {
      width: 100%;
      padding: 53px 30px;
    }
  }

  &__close-icon {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
}

.modal__wrapper {
  overflow: hidden;
  z-index: 999999;
}
</style>
