import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import authnew from "./modules/authnew";
import apimodel from "./modules/apimodel";
import settings from "./modules/settings";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const { plugin: vuexPlugin } = new VuexPersistence({
  storage: window.localStorage
});

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    authnew,
    apimodel,
    settings
  },
  plugins: [vuexPlugin]
});
