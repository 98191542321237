import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    },
    linkExactActiveClass: "exact-active",
});

export default router;
