import api from "../../../services/api";
import queryConcatenator from "../../../utils/queryConcatenator";

export default {
  async makeRequest({ commit }, payload = {}) {
    // This updates the URL and checks if queries should be passed or not
    if (payload.commitInit?.length) {
      commit(payload.commitInit[0], payload.commitInit[1]);
    }
    let [url, urlHasQueries] = [
      `${payload.url}?`,
      !!Object.entries(payload.queries ?? {}).length,
    ];

    urlHasQueries && (url = queryConcatenator(url, payload.queries));

    try {
      // API call
      const response = await api[payload.type || "get"](
        url,
        payload.data || ""
      );
      if (payload.commitSuccess?.length && response) {
        commit(payload.commitSuccess[0], response);
      }
      // Resolves if request is fulfilled
      return Promise.resolve(response);
    } catch (error) {
      if (payload.commitError?.length) {
        commit(payload.commitError[0], error);
      }
      // Rejects if request fails
      return Promise.reject(error.response.data.message);
    }
  },
};
