<template>
  <ValidationProvider
    class="form__group"
    tag="div"
    v-slot="{ errors, required, ariaInput, ariaMsg }"
    :rules="isRequired ? { required: { allowFalse: !isRequired } } : ''"
  >
    <div class="radio__btn">
      <input
        :value="value"
        @change="$emit('change', $event.target.value)"
        :name="name"
        :id="id"
        type="radio"
        ref="input"
      />
      <label class="radio__btn-input" :for="id">
        <span class="radio__btn-round"></span>
        <span class="radio__btn-text fw-400 fs-14">{{ label }}</span>
      </label>
    </div>
    <span v-bind="ariaMsg" class="radio__errors">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import "../utils/veeValidateRules";
import { ValidationProvider } from "vee-validate";

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    value: {
      required: true,
      type: String
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationProvider
  },

  mounted() {
    console.log(this.checked);
    if (this.checked) {
      this.$refs.input.checked = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.radio__btn {
  input {
    display: none;
  }

  &-input {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &-round {
    width: 20px;
    height: 20px;
    border: 2px solid #222222;
    border-radius: 50%;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      transform: translate(-50%, -50%) scale(0);
      top: 50%;
      left: 50%;
      transition: 0.3s all ease-out;
      border-radius: 50%;
    }
  }

  &-text {
    display: inline-block;
    color: #505565;
    transition: 0.3s ease-out;
    margin-left: 10px;
  }

  input:checked ~ .radio__btn-input .radio__btn-round:after {
    background: #3dbd38;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
  &__errors {
    font-size: 12px;
    color: #ff0000;
  }
}
</style>
