<script>
import TechTalentHeader from "../components/TechTalentHeader.vue";
import TechTalentNav from "../components/TechTalentNav.vue";

export default {
  data: () => ({
    collapsed: false
  }),

  methods: {
    fixCollapse() {
      if (innerWidth <= 720) {
        this.collapsed = true;
      }
    }
  },
  mounted() {
    this.fixCollapse();
    addEventListener("resize", (event) => {
      this.fixCollapse();
    });
  },
  components: {
    TechTalentHeader,
    TechTalentNav
  }
};
</script>

<template>
  <div class="dashboard">
    <div
      class="dashboard__left"
      :class="{ 'dashboard__left--collapsed': collapsed }"
    >
      <div class="dashboard__brand">
        <img src="/img/infohub-blue-logo.682318e0.svg" alt="" />
        <div class="d-inline-block d-md-none">
          <FIcon icon="bars" @click="collapsed = true"></FIcon>
        </div>
      </div>
      <div class="dashboard__navigation">
        <TechTalentNav @nav-click="collapsed = true"></TechTalentNav>
        <!-- Admin Navs -->
      </div>
    </div>
    <div class="dashboard__right">
      <div class="dashboard__top">
        <TechTalentHeader
          @navIconClick="collapsed = !collapsed"
        ></TechTalentHeader>
      </div>
      <div class="dashboard__main">
        <slot name="main"> #main </slot>
      </div>
    </div>
  </div>
</template>

<style>
.dashboard {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  background-color: #f9f9f9;
}
.dashboard__left {
  left: 0px;
  width: 277px;
  z-index: 3;
  display: flex;
  background-color: #f9f9f9;
  flex-direction: column;
  transition: all 0.25s;
}

.dashboard__left--collapsed {
  left: -277px !important;
}

.dashboard__brand {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
}

.dashboard__navigation {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.dashboard__right {
  flex: 1;
  width: 222px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.dashboard__top {
  height: 77px;
  z-index: 2;
  display: flex;
  padding: 0 50px;
  background-color: #f9f9f9;
}
.dashboard__main {
  flex: 1;
  padding: 20px 50px;
  background-color: #fff;
  border-radius: 38px 0px 0px 38px;
  overflow-y: auto;
  margin-bottom: 30px;
  position: relative;
}

@media screen and (max-width: 700px) {
  .dashboard__left {
    /* display: none; */
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.12);
  }
  .dashboard__left.responsive {
    display: flex;
  }

  .dashboard__top {
    padding: 18px;
  }
  .dashboard__main {
    padding: 20px;
    border-radius: 0;
    margin-bottom: 0;
  }
}
</style>
