import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PortalVue from "portal-vue";
import "./utils/veeValidateRules";
// import "./utils/googleAuth";
import "./utils/notification";
import moment from "moment";
import "./utils/globalComponents";
import userAccessMixin from "./mixins/userAccessMixin";
// import "./mixins/countriesMixin";
import { handleError, handleSuccess } from "./utils/functions";
import fontawesome from "./plugins/font-awesome";

Vue.use(PortalVue);

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.$handleError = handleError;

Vue.use(fontawesome);

const app = new Vue({
  mixins: [userAccessMixin],
  router,
  store,

  render: (h) => h(App)
}).$mount("#app");

Vue.prototype.$handleError = (e) => {
  handleError(app, e);
};

Vue.prototype.$handleSuccess = (res) => {
  handleSuccess(app, res);
};
