<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// import MyProfile from "./SubPages/MyProfile.vue";
// import Educations from "./SubPages/Educations.vue";
// import Skills from "./SubPages/Skills.vue";
// import SocialNetworks from "./SubPages/SocialNetworks.vue";
// import Projects from "./SubPages/Projects";
// import Resume from "./SubPages/Resume.vue";
import API from "@/const";
// import seekerService from "../../../services/api/seekerService";
import seekerService from "../services/api/seekerService";
import TechTalent from "../layouts/TechTalent.vue";

export default {
  components: {
    TechTalent
  },
  data() {
    return {
      countries: [],
      loading: true,
      collapsed: false
    };
  },
  created() {
    this.getProfileData();
  },
  computed: {
    ...mapGetters({
      profileData: "authnew/getProfileData"
    })
  },
  methods: {
    ...mapMutations({
      setProfileData: "authnew/setProfileData"
    }),
    ...mapActions({}),
    getProfileData() {
      this.loading = true;
      seekerService
        .getSeekerProfile()
        .then((res) => {
          // console.log(res.data);
          // this.profileData = res.data;
          this.setProfileData(res.data);
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async refreshData({ tab = "" }) {
      await this.getProfileData();
      if (tab) this.getCurrentTab(tab);
    }
  }
};
</script>

<template>
  <TechTalent>
    <template #main>
      <router-view :profileData="profileData" @on-success="refreshData" />
    </template>
  </TechTalent>
</template>

<style>
.sub-page {
  max-width: 800px;
  /* margin-left: auto;
  margin-right: auto; */
  margin-left: 22px;
  padding: 22px 0;
}
</style>
