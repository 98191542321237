import { axiosGuest } from "../axiosInstance";
// import axiosPublic from "../index";
import API_ENDPOINTS from "../../const/index";
import jwtDecode from "jwt-decode";
import { axiosPrivate } from "..";

export default {
  register(payload) {
    return axiosGuest.post("/auth/register", payload);
  },
  login(payload) {
    return axiosGuest.post("/auth/login", payload);
  },
  refreshToken(payload) {
    return axiosPrivate.post("/auth/refresh-token", payload);
  },
  resendOtp() {
    return axiosPrivate.post("/auth/resend-otp", {});
  },
  verifyEmail(payload, token) {
    return axiosGuest.post("/auth/verify-email", payload, {
      headers: {
        "Content-Type": "application/json",
        authorization: token
      }
    });
  },
  getDegrees() {
    return axiosGuest.get("/public/degrees");
  },
  getAllCountries() {
    return axiosGuest.get("/public/countries/");
  },
  getStates(countryId) {
    return axiosGuest.get("/public/countries/" + countryId + "?states=yes");
  },

  onboard(payload, token) {
    return axiosGuest.post("/seeker/onboard", payload, {
      headers: {
        "Content-Type": "application/json",
        authorization: token
      }
    });
  },

  signInWithGoogle1(idToken) {
    return axiosPublic.post(API_ENDPOINTS.OAUTH_CALLBACK, {
      id_token: idToken
    });
  },

  async signInWithGoogle(idToken) {
    try {
      const response = await axiosPublic.post(API_ENDPOINTS.OAUTH_CALLBACK, {
        id_token: idToken
      });

      if (!response.data.error) {
        const {
          access_token: token,
          refresh_token,
          on_boarding
        } = response.data.data;
        axiosPublic.defaults.headers.Authorization = `Bearer ${token}`;

        const {
          realm_access,
          resource_access,
          preferred_username: username,
          email
        } = jwtDecode(token);

        return Promise.resolve({
          realm_access,
          resource_access,
          username,
          email,
          token,
          refresh_token,
          on_boarding
        });
      } else {
        return Promise.reject(response.data.message);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  requestResetPassword(payload) {
    return axiosGuest.post("/auth/request-reset-password/", payload);
  },
  resetPassword(payload, token) {
    return axiosGuest.post("/auth/reset-password/", payload, {
      headers: {
        "Content-Type": "application/json",
        authorization: token
      }
    });
  }
};
