/* eslint-disable no-underscore-dangle */
import axios from "axios";
// import NProgress from 'nprogress';
import router from "../router";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 36000,
  headers: {
    "Content-Type": "application/json"
  }
});

instance.interceptors.request.use((config) => {
  // check if user is authenticated or making auth requests
  // NProgress.start();

  return config;
});

instance.interceptors.response.use(
  (response) => {
    // NProgress.done();

    return response;
  },
  (error) => {
    // NProgress.done();

    if (error.code === "ECONNABORTED") {
      // TODO: Add toast message

      return error;
    }
    // eslint-disable-next-line no-underscore-dangle
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      router.push({ name: "login" });
    }
    if (error.response.status === 500) {
      // TODO: handle server error
    }

    return Promise.reject(error);
  }
);

export const axiosGuest = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 36000,
  headers: {
    "Content-Type": "application/json"
  }
});

export const axiosPrivate = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 36000,
  headers: {
    "Content-Type": "application/json"
  }
});

export const setPrivateHaders = (token) => {
  console.log("setting token...");
  axiosPrivate.defaults.headers.common["authorization"] = token;
};

// Add a response interceptor
axiosPrivate.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    console.dir(error);
    console.dir("error");

    // Do something with response error
    if (error.response && error.response.status == 401) {
      if (window.location.href.indexOf("force-logout") === -1) {
        setTimeout(() => {
          window.location.href = "/force-logout";
        }, 111);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
