import axiosInstance from "../services";
import { mapGetters } from "vuex";

export default {
  created() {
    this.validateUser();
  },
  methods: {
    ...mapGetters({
      loggedUser: "auth/getLoggedUser",
    }),
    validateUser() {
      if (this.loggedUser()?.token) {
        axiosInstance.defaults.headers.Authorization = `Bearer ${
          this.loggedUser()?.token
        }`;
      }
    },
  },
};
