import instance from "../../../services/api";
import API from "../../../const";
import jwtDecode from "jwt-decode";
import axios from "../../../services";

export default {
  async createJobSeeker({ commit }, payload) {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await instance.post(API.CREATE_USER, formData);
      // commit("updateLoggedUser", response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async forgotPassword(_, payload) {
    try {
      const response = await instance.put(API.FORGOT_PASSWORD, payload);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async loginUser({ commit }, payload) {
    try {
      const response = await instance.post(API.LOGIN_USER, payload);

      if (!response.data.error) {
        const {
          access_token: token,
          refresh_token,
          on_boarding
        } = response.data.data;
        axios.defaults.headers.Authorization = `Bearer ${token}`;

        const {
          realm_access,
          resource_access,
          preferred_username: username,
          email,
          name
        } = jwtDecode(token);
        commit("setLoggedUser", {
          realm_access,
          resource_access,
          username,
          email,
          name,
          token,
          refresh_token,
          on_boarding
        });
      }

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
