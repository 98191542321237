<script>
import { mapGetters, mapMutations } from "vuex";
import avatarIcon from "../assets/icons/avatar-icon.svg";
import seekerService from "../services/api/seekerService";

// const router = useRouter();
export default {
  data: () => ({
    showProfileDropdown: false,
    formData: {
      profilePhoto: ""
    }
  }),
  methods: {
    ...mapMutations({
      logOut: "authnew/logOutUser",
      updateLoggedUser: "auth/updateLoggedUser",
      setUserData: "authnew/setUserData",
      setProfile: "authnew/setProfile",
      setProfileData: "authnew/setProfileData"
    }),
    closeProfileDropdown() {
      this.showProfileDropdown = false;
    },
    logoutNow() {
      this.$router.push("/force-logout");
    },
    forceLogout() {
      this.logOut();
      this.handleAuth();
    },
    handleAuth() {
      // !this.loggedIn.active
      //   ? this.$router.push({ name: "login" })
      //   : this.logOut();
      !this.userData.token
        ? this.$router.push({ name: "login" })
        : this.logOut();
    },
    uploadImg(e) {
      const fileSize = 200 * 1024;
      const img = new FileReader();
      const vm = this;

      img.onload = (evt) => {
        console.log(evt.total);
        // Max of 5MB
        if (evt.total < fileSize) {
          vm.formData.profilePhoto = evt.target.result;
          let fd = new FormData();
          fd.append("profilePhoto", e.target.files[0]);
          this.updatingPhoto = true;
          seekerService
            .uploadProfilePhoto(fd, (percentage) => {
              console.log(percentage);
            })
            .then((res) => {
              this.$handleSuccess(res);
              this.setProfile(res.data.profile);
              // this.$emit("profileChanged", res.data.profile);
            })
            .catch((e) => {
              this.$handleError(e);
            })
            .finally(() => {
              this.updatingPhoto = true;
            });
        } else {
          this.$handleError("File too large. Max file size allowed is 200KB");
        }
      };
      img.readAsDataURL(e.target.files[0]);
    }
  },
  computed: {
    ...mapGetters({
      userData: "authnew/getUserData",
      profileData: "authnew/getProfileData"
    }),
    avatarIcon() {
      // console.log(this.profileData);
      return this.profileData.profile
        ? this.profileData.profile.profileImage
        : "/img/avatar-icon.svg";
    }
  },
  mounted() {
    const token = this.userData.token;
    if (!token) {
      return;
    }

    // this.loading = true;
    seekerService
      .getSeekerProfile()
      .then((res) => {
        this.setProfileData(res.data);
      })
      .catch((e) => {
        // this.$handleError(e);
      })
      .finally(() => {
        // this.loading = false;
      });
  }
};
</script>

<template>
  <div class="admin-header flex-1 flex jc-between ai-center">
    <div class="admin-header__left flex ai-center">
      <div class="admin-nav-icon mr-3 d-md-none" @click="$emit('navIconClick')">
        <img class="admin-hamburger" src="/img/icons/burger.svg" alt="" />
        <span class="ml-1">Job Seeker</span>
      </div>
      <div class="d-none d-md-inline-block">Job Seeker</div>
    </div>
    <div class="admin-header__right flex ai-center">
      <!-- <router-link to="/job-seeker/jobs"> Jobs </router-link>
      <router-link to="/job-seeker/applications"> My Applications </router-link>
      <router-link to="/job-seeker/my-account"> My Account </router-link> -->
      <!-- <div class="c-pointer" @click="logoutNow">
        Auwalu Adamu <FIcon icon="angle-down" class="ml-2"></FIcon>
      </div> -->
      <div
        class="cursor-pointer user__profile"
        tabindex="-1"
        @click="showProfileDropdown = !showProfileDropdown"
        @blur="closeProfileDropdown"
      >
        <img :src="avatarIcon" class="avatar-icon" alt="avatar-icon" />
        <div
          class="user__profile-options"
          :class="{ active: showProfileDropdown }"
          v-show="showProfileDropdown"
        >
          <p>Profile</p>
          <!-- <p @click="$router.push({ name: 'my-account' })">Profile</p> -->
          <p @click="$refs['user-img'].click()">Upload Photo</p>
          <p @click="forceLogout">Logout</p>

          <input
            type="file"
            ref="user-img"
            hidden
            @change="uploadImg"
            accept=".png, .jpg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}

.jc-between {
  justify-content: space-between;
}
.ai-center {
  align-items: center;
}

.admin-nav-icon {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.d-inline-block {
  display: inline-block;
}

.admin-header__right a {
  display: inline-block;
  margin: 0 22px;
  font-size: 1.6rem;
  font-weight: 600;
}

.admin-header__right a:hover {
  color: #104977;
}

.admin-header__right a.router-link-active {
  color: #0385f3;
}

.avatar-icon {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.admin-hamburger {
  height: 33px;
}

.d-md-none {
}

@media screen and (min-width: 800px) {
  .d-md-none {
    display: none;
  }
}

.user {
  &__profile {
    position: relative;
    width: 40px;
    height: 40px;

    &-options {
      position: absolute;
      top: 47px;
      opacity: 0;
      transition: 0.3s all ease-out;
      right: 0;
      background: #fff;
      min-width: 110px;
      border-radius: 4px;
      box-shadow: 0px 4px 4px 10px rgba(0, 0, 0, 0.02);
      overflow: hidden;

      & > * {
        padding: 8px 10px;
        transition: 0.3s all ease-out;

        &:hover {
          background: darken(#fff, 5%);
        }
      }

      &.active {
        opacity: 1;
        top: 44px;
      }
    }

    &--full {
      position: relative;
      width: 100%;
      height: 100%;

      & .user__profile-options {
        top: 20px;
        transition: all 0.25s;
        opacity: 0;
      }
    }
  }
}
</style>
