export default {
    data() {
        return {
            authRoutes: [
                "login",
                "forgotPassword",
                "resetPassword",
                "createAccount",
                "verifyEmail",
                "adminLogin",
                "adminForgotPassword",
                "adminResetPassword",
            ],
        };
    },
    computed: {
        animationName() {
            if (this.authRoutes.includes(this.$route.name)) {
                return "fade-from-top";
            }

            return "fade-in";
        },
    },
};
