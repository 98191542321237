import { authGuard, loginGuard, onboardingGuard } from '../utils/routeGuards';
import TechTalent from '../pages/TechTalent.vue';

const NotFound = () => import('../views/NotFound');
const Home = () => import('../views/home/Index.vue');
const Login = () => import('../views/JobSeeker/auth/Login.vue');
const ForceLogout = () => import('../views/JobSeeker/auth/ForceLogout.vue');
const ForgotPassword = () =>
  import('../views/JobSeeker/auth/ForgotPassword.vue');
const ResetPassword = () => import('../views/JobSeeker/auth/ResetPassword.vue');
const CreateAccount = () => import('../views/JobSeeker/auth/CreateAccount.vue');
const VerifyEmail = () => import('../views/JobSeeker/auth/VerifyEmail.vue');
const Onboarding = () => import('../views/JobSeeker/onboarding/Index.vue');

const Jobs = () => import('../views/jobs');
const Job = () => import('../views/jobs/Job');
const MyAccount = () => import('../views/MyAccount');
const Applications = () => import('../views/JobSeeker/Applications');
const JobSeekerProfile = () => import('../views/JobSeeker/profile/Index.vue');
const AdminLogin = () => import('../views/admin/auth/login');
const AdminForgotPassword = () => import('../views/admin/auth/forgotPassword');
const AdminResetPassword = () => import('../views/admin/auth/resetPassword');
const AdminDashboard = () => import('../views/admin/dashboard');
const AdminOverview = () => import('../views/admin/dashboard/overview');
const AdminJobSeekers = () => import('../views/admin/dashboard/jobSeekers');
const AdminJobs = () => import('../views/admin/dashboard/jobs');

const LandingPage = () => import('../views/LandingPage.vue');
// const LandingPage = () => import("../views/landingPage/LandingPage.vue");

const HireTalent = () => import('../views/HireTalent.vue');
const Services = () => import('../views/Services.vue');
const FAQ = () => import('../views/FAQ.vue');
// const HireTalent = () => import("../views/landingPage/HireTalent.vue");
const Terms = () => import('../views/landingPage/Terms.vue');
const ContactUs = () => import('../views/ContactUs.vue');
const FindAJob = () => import('../views/landingPage/findAJob');
const AboutUS = () => import('../views/AboutUs.vue');
const TechTalentJobs = () => import('../pages/tech-talents/Jobs.vue');
const MyProfile = () => import('../pages/tech-talents/MyProfile.vue');
const Educations = () => import('../pages/tech-talents/Educations.vue');

const routes = [
  {
    path: '/',
    name: 'landing-page',
    component: Login
  },
  { path: '/404', component: NotFound },
  { path: '*', redirect: '/404' },
  {
    name: 'my-account',
    path: '/job-seeker/my-account',
    component: TechTalent,
    redirect: '/job-seeker/my-account/my-profile',
    children: [
      {
        name: 'phone-verification',
        path: 'phone-verification',
        component: () => import('../pages/tech-talents/PhoneVerification.vue')
      },
      {
        path: 'my-profile',
        component: MyProfile
      },
      {
        path: 'educations',
        component: Educations
      },
      {
        path: 'exp-and-skills',
        component: () => import('../pages/tech-talents/Skills.vue')
      },
      {
        path: 'social-networks',
        component: () => import('../pages/tech-talents/SocialNetworks.vue')
      },
      {
        path: 'resume-cv',
        component: () => import('../pages/tech-talents/Resume.vue')
      },

      {
        path: '*',
        component: TechTalentJobs
      }
    ]
  },
  {
    path: '/job-seeker/home',
    name: 'home',
    component: Home
  },
  {
    path: '/job-seeker/login',
    name: 'login',
    component: Login
  },
  {
    path: '/force-logout',
    name: 'force-logout',
    component: ForceLogout
  },
  {
    path: '/job-seeker/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword
  },
  {
    path: '/job-seeker/reset-password/:token',
    name: 'resetPassword',
    component: ResetPassword,
    props: true
  },
  {
    path: '/job-seeker/sign-up',
    name: 'createAccount',
    component: CreateAccount
  },
  {
    path: '/job-seeker/verify',
    name: 'verifyEmail',
    component: VerifyEmail
  },
  {
    path: '/job-seeker/onboarding',
    name: 'onboarding',
    component: Onboarding,
    beforeEnter: onboardingGuard
  },
  {
    path: '/job-seeker/jobs',
    name: 'jobs',
    component: Jobs
  },
  {
    path: '/job-seeker/jobs/:profession',
    name: 'job',
    props: true,
    component: Job
  },
  {
    path: '/job-seeker/account',
    name: 'account',
    component: MyAccount
  },
  {
    path: '/job-seeker/applications',
    name: 'applications',
    component: Applications
  },
  {
    path: '/job-seeker/update-profile',
    name: 'update-profile',
    component: JobSeekerProfile
  },
  {
    path: '/admin/login',
    name: 'adminLogin',
    component: AdminLogin
  },
  {
    path: '/admin/forgot-password',
    name: 'adminForgotPassword',
    component: AdminForgotPassword
  },
  {
    path: '/admin/reset-password/:token',
    name: 'adminResetPassword',
    component: AdminResetPassword,
    props: true
  },
  {
    path: '/admin',
    name: 'adminDashboard',
    component: AdminDashboard,
    children: [
      {
        path: 'dashboard',
        alias: '',
        name: 'overview',
        component: AdminOverview
      },
      {
        path: 'job-seekers',
        name: 'adminJobSeekers',
        component: AdminJobSeekers
      },
      {
        path: 'jobs',
        name: 'adminJobs',
        component: AdminJobs
      }
    ]
  },

  {
    path: '/hire-talent',
    name: 'hire-talent',
    component: HireTalent
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ
  },
  {
    path: '/find-job',
    name: 'find-job',
    component: FindAJob
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUS
  },

  {
    path: '/terms',
    name: 'terms',
    component: Terms
  }
];

export default routes;
