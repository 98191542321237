<template>
    <portal to="modal-portal">
        <div
            :class="`u-absolute modal__wrapper ${
                !showModal ? 'modal__wrapper-closed' : ''
            }`"
        >
            <div
                @click="showModal = !showModal"
                :class="`modal__overlay u-fixed ${
                    !showModal ? 'modal__overlay-closed' : ''
                }`"
            ></div>

            <div
                :class="[
                    'u-relative',
                    'modal__body',
                    `${!showModal ? 'modal__body-closed' : ''}`,
                    `modal__body-${position}`,
                    fullMobileWidth ? 'full__mobile-width' : '',
                    size ? `modal__body-${size}` : '',
                ]"
            >
                <slot></slot>
                <button
                    v-if="showCloseIcon"
                    @click="showModal = !showModal"
                    class="modal__close-icon cursor-pointer"
                >
                    <img
                        src="../../assets/icons/close-icon.svg"
                        alt="close-icon"
                    />
                </button>
            </div>
        </div>
    </portal>
</template>

<script>
export default {
    name: "Modal",
    props: {
        position: {
            type: String,
            default: "center",
        },
        active: {
            type: Boolean,
        },
        showCloseIcon: {
            type: Boolean,
            default: true,
        },
        fullMobileWidth: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            showModal: false,
        };
    },
    watch: {
        active() {
            this.showModal = !this.showModal;
        },
        showModal(value) {
            if (value) {
                document.body.style.overflow = "hidden";

                return;
            }

            document.body.style.overflow = "auto";
        },
    },
};
</script>

<style scoped lang="scss">
@import "./Modal.scss";
</style>
