import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("between", {
  validate: (value, { min, max }) => {
    if (value.length >= min && value.length <= max) {
      return true;
    }

    return `Length must be between ${min} and ${max}`;
  },
  params: ["min", "max"]
});

extend("fullName", {
  validate: (value) => {
    if (value.split(" ").length > 1 && value.split(" ")[1]) {
      return true;
    }

    return `First and Last name is required`;
  }
});

// extend("username", {
//   validate: (value) => {
//     if (value.split(" ").length > 1 && value.split(" ")[1]) {
//       return true;
//     }

//     return `First and Last name is required`;
//   }
// });

extend("url", {
  validate: (value) => {
    if (
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gu.test(
        value
      )
    ) {
      return true;
    }

    return `Enter a valid URL`;
  }
});
