<script>
export default {
  data: () => ({
    items: [
      {
        name: "Phone Verification",
        to: "/job-seeker/my-account/phone-verification",
        icon: ""
      },
      {
        name: "My Profile",
        to: "/job-seeker/my-account/my-profile",
        icon: ""
      },
      {
        name: "Educations",
        to: "/job-seeker/my-account/educations",
        icon: ""
      },
      {
        name: "Experience and Skills",
        to: "/job-seeker/my-account/exp-and-skills",
        icon: ""
      },
      {
        name: "Social Networks",
        to: "/job-seeker/my-account/social-networks",
        icon: ""
      },
      {
        name: "Resume / CV",
        to: "/job-seeker/my-account/resume-cv",
        icon: ""
      },
      {
        name: "My Applications",
        to: "/job-seeker/my-account/my-applications",
        icon: ""
      }
    ]
  })
};
// const emit = defineEmits(["nav-click"]);
</script>

<template>
  <div class="admin-navs">
    <router-link
      v-for="item in items"
      @click.native="$emit('nav-click')"
      class="admin-nav d-block"
      :to="item.to"
    >
      <div class="flex ai-center">
        <!-- <div class="admin-nav__icon">
          <FIcon :icon="item.icon"></FIcon>
        </div> -->
        <div class="admin-nav__text flex-1">
          {{ item.name }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<style scoped>
.admin-nav {
  margin: 5px 0;
  padding: 11px 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  border-radius: 8px;
  width: 100%;
}

.admin-nav:hover {
  background-color: rgb(249, 255, 255);
  /* font-weight: 700; */
}

.admin-nav__icon {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-navs .router-link-active,
.admin-navs .router-link-active:hover {
  background: #1aa7ec;
  font-weight: 700;
  color: #ffffff;
  border-radius: 8px;
  color: #fff;
}
</style>
