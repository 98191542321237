import store from "../store";

const loggedUser = () => store.getters["auth/getLoggedUser"];
const loggedIn = () => store.getters["auth/getLoggedIn"];
const userData = () => store.getters["authnew/getUserData"];

export const authGuard = (to, from, next) => {
  console.log(userData());
  if (userData().token) {
    if (userData().user.requiredAction === "completeProfile") {
      next({ name: "onboarding" });
      return;
    }
    next();
  } else {
    next({ name: "login" });
  }
};

export const loginGuard = (to, from, next) => {
  if (userData().token) {
    next({ name: "home" });
  } else {
    next();
  }
};

export const onboardingGuard = (to, from, next) => {
  if (loggedUser()?.on_boarding) {
    next({ name: "home" });
  } else {
    next();
  }
};
