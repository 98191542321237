import axios from "../../../services";
import router from "../../../router";

export default {
    setLoggedUser(state, payload) {
        state.loggedUser = payload;
        if (payload.realm_access.roles.includes("seeker")) {
            state.loggedIn = { as: "seeker", active: true };

            return;
        }

        state.loggedIn = { as: "recruiter", active: true };
    },
    updateLoggedUser(state, payload) {
        state.loggedUser = {
            ...state.loggedUser,
            ...payload
        };
    },
    logOutUser(state) {
        state.loggedUser = {};
        state.loggedIn = {
            as: "",
            active: false
        };
        axios.defaults.headers.Authorization = "";
        router.push({ name: "login" });
    },

    logOutAdmin(state) {
        state.loggedUser = {};
        state.loggedIn = {
            as: "",
            active: false
        };
        axios.defaults.headers.Authorization = "";
        router.push({ name: "adminLogin" });
    }
};
