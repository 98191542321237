// import { axiosGuest, axiosPrivate } from "./axiosInstance";
import { axiosPrivate } from '../index';

export default {
  getSeekerProfile() {
    return axiosPrivate.get('/seeker/profile');
  },
  uploadCv(payload, progressCallback) {
    return axiosPrivate.post('/seeker/document/', payload, {
      onDownloadProgress: function (uploadEvent) {
        const percentage = Math.round(
          (uploadEvent.loaded / uploadEvent.total) * 100
        );

        if (progressCallback) {
          progressCallback(percentage);
        }
      }
    });
  },
  getCvs() {
    return axiosPrivate.get('/seeker/document/');
  },
  deleteCv(payload) {
    return axiosPrivate.delete('/seeker/document/' + payload);
  },
  uploadProfilePhoto(payload, progressCallback) {
    return axiosPrivate.put('/seeker/profile-photo', payload, {
      onDownloadProgress: function (uploadEvent) {
        const percentage = Math.round(
          (uploadEvent.loaded / uploadEvent.total) * 100
        );

        if (progressCallback) {
          progressCallback(percentage);
        }
      }
    });
  },
  updateProfile(payload) {
    return axiosPrivate.put('/seeker/profile', payload);
  },

  addNewEducation(payload) {
    return axiosPrivate.post('/seeker/education/', payload);
  },
  updateEducation(payload) {
    return axiosPrivate.put('/seeker/education/' + payload.id, payload);
  },
  deleteEducation(payload) {
    return axiosPrivate.delete('/seeker/education/' + payload);
  },
  addNewExperience(payload) {
    return axiosPrivate.post('/seeker/experience/', payload);
  },
  updateExperience(payload) {
    return axiosPrivate.put('/seeker/experience/' + payload.id, payload);
  },
  updateSkills(payload) {
    return axiosPrivate.put('/seeker/skills', payload);
  },
  deleteExperience(payload) {
    return axiosPrivate.delete('/seeker/experience/' + payload);
  },
  addNewSocialNetwork(payload) {
    return axiosPrivate.post('/seeker/social-network/', payload);
  },
  updateSocialNetwork(payload) {
    return axiosPrivate.put('/seeker/social-network/' + payload.id, payload);
  },

  deleteSocialNetwork(payload) {
    return axiosPrivate.delete('/seeker/social-network/' + payload);
  },
  verifyPhone(payload) {
    return axiosPrivate.post('/seeker/phone/', payload);
  },
  verifyPhoneOTP(payload) {
    return axiosPrivate.post('/seeker/phone/verify', payload);
  },
  changePhone(payload) {
    return axiosPrivate.post('/seeker/phone/change', payload);
  },
  changePhoneOTP(payload) {
    return axiosPrivate.post('/seeker/phone/verify-change', payload);
  }
};
