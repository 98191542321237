var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":_vm.target}},[_c('div',{ref:"wrapper",class:("u-fixed modal__wrapper " + (!_vm.value ? 'modal__wrapper-closed' : ''))},[_c('div',{class:("modal__overlay u-fixed " + (!_vm.value ? 'modal__overlay-closed' : '')),on:{"click":_vm.closeModal}}),_c('div',{class:[
        'u-relative',
        'modal__body',
        ("" + (!_vm.value ? 'modal__body-closed' : '')),
        ("modal__body-" + _vm.position),
        _vm.fullMobileWidth ? 'full__mobile-width' : '',
        _vm.size ? ("modal__body-" + _vm.size) : ''
      ]},[_vm._t("default"),(_vm.showCloseIcon)?_c('button',{staticClass:"modal__close-icon cursor-pointer",on:{"click":_vm.closeModal}},[_c('img',{attrs:{"src":require("../assets/icons/close-icon.svg"),"alt":"close-icon"}})]):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }