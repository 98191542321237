<template>
  <div class="u-relative dropdown" :style="{ zIndex: zIndex }">
    <button
      v-if="!child"
      class="flex flex__spaced flex__item-center dropdown__control"
      :class="[
        border ? 'dropdown__control-border' : '',
        disabled ? 'disabled' : '',
        type,
        error ? 'dropdown--error' : ''
      ]"
      @click.prevent="!disabled && handleToggle()"
      @blur="handleBlur"
      :disabled="disabled"
    >
      <span
        class="dropdown__text text-capitalize"
        :style="`color: ${textColor}`"
      >
        {{ selected || placeholder }}
      </span>
      <div class="dropdown__arrow flex" :class="{ show: showOptions }">
        <svg
          v-if="icon === 'primary'"
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0L5 5L10 0H0Z" fill="#A1A4B1" />
        </svg>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          v-else
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L7 7L13 1"
            stroke="#4E4E5A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </button>
    <slot
      v-else
      name="actions"
      :handleToggle="handleToggle"
      :handleBlur="handleBlur"
    ></slot>

    <ul
      class="dropdown__options"
      :class="[showOptions ? 'dropdown__options-active' : '', optionsClass]"
    >
      <template v-if="options.length">
        <li
          class="dropdown__options-item"
          v-for="(option, index) in options"
          :key="index"
          @click="setSelected(option)"
        >
          {{ option.name }}
        </li>
      </template>
      <template v-else>
        <li class="dropdown__options-item">No options available</li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    options: {
      type: Array,
      default: () => []
    },
    child: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'primary'
    },
    textColor: {
      type: String,
      default: '#8b90a0'
    },
    type: {
      type: String,
      default: ''
    },
    optionsClass: {
      type: String,
      default: ''
    },
    zIndex: {
      default: 10
    },
    error: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    selected: '',
    showOptions: false,
    isOnDocument: true
  }),
  created() {
    document.addEventListener('visibilitychange', () => {
      this.isOnDocument = !document.hidden;
    });
  },
  methods: {
    handleToggle() {
      this.showOptions = !this.showOptions;
    },
    handleBlur() {
      if (this.showOptions && this.isOnDocument) {
        setTimeout(() => (this.showOptions = false), 200);
      }
    },
    setSelected(option) {
      this.$emit('selected', option);
      this.selected = option.name;
      setTimeout(() => (this.showOptions = false), 200);
    }
  },
  destroyed() {
    document.removeEventListener('visibilitychange', () => {});
  }
};
</script>

<style scoped lang="scss">
@import './dropdown.scss';
.dropdown--error {
  border: 1px solid rgb(255, 0, 0);
  box-shadow: 0px 0px 7px 1px rgba(255, 0, 0, 0.5);
}
</style>
