<template>
  <label class="toggler" :class="{ checked }" :for="id">
    <input
      type="checkbox"
      v-model="checked"
      hidden
      :id="id"
      @click="checked = !checked"
    />
    <div class="toggler__circle" />
  </label>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    id: {
      type: String,
      default: "toggler" + Math.random()
    },
    defaultValue: {
      type: Boolean,
      default: false
    },
    value: {
      default: false
    }
  },
  data() {
    return {
      checked: false
    };
  },
  watch: {
    checked(value) {
      this.$emit("input", value);
    },
    defaultValue: {
      handler(val) {
        if (val) this.$nextTick(() => (this.checked = val));
      },
      immediate: true
    }
  },
  created() {
    this.checked = this.value;
  }
};
</script>

<style lang="scss" scoped>
.toggler {
  background: #cccccc;
  border-radius: 11.5px;
  width: 38px;
  display: flex;
  align-items: flex-start;
  padding: 0.2rem;
  cursor: pointer;

  &.checked {
    background: #1aa7ec;
  }

  &__circle {
    transition: 0.3s all ease-out;
    position: relative;
    left: 0;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 11.5px;
  }

  & input:checked + &__circle {
    left: calc(100% - 16px);
  }
}
</style>
