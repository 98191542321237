import Vue from "vue";
import Dropdown from "../components/Dropdown";
import Button from "../components/Button";
import Modal from "../components/Modal";
import NewModal from "../components/NewModal";
import NewToggle from "../components/NewToggle";
import Input from "../components/Input";
import NewRadio from "../components/NewRadio.vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { ValidationObserver } from "vee-validate";

Vue.component("Dropdown", Dropdown);
Vue.component("VueTelInput", VueTelInput);
Vue.component("Input", Input);
Vue.component("Button", Button);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("Modal", Modal);
Vue.component("NewModal", NewModal);
Vue.component("NewToggle", NewToggle);
Vue.component("NewRadio", NewRadio);
