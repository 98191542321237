export default {
  CREATE_USER: "user/create",
  LOGIN_USER: "user/login",
  FORGOT_PASSWORD: "user/send_forget_password_email",
  SEND_OTP: "/user/send_otp",
  ACTIVATE_USER: "user/activate",
  CHANGE_PASSWORD: "/user/change_password",
  PROFILE_SUMMARY: "/user/profile_summary",
  USER_EDUCATION: "/user/education",
  ADD_EXPERIENCE: "/user/experience",
  ADD_DOCUMENT: "/user/document",
  UPDATE_PROFILE: "user/profile",
  ADD_SKILLS: "user/key_skills",
  GET_PROFILE_DETAILS: "/user/user_profile",
  KEY_SKILLS: "/user/key_skills",
  OAUTH_CALLBACK: "user/callback_oauth",
  USER_PROFILE: "user/user_profile?",
  ADMIN_LOGIN: "admin/login",
  ADMIN_FORGOT_PASSWORD: "admin/send_forget_password_email",
  ADMIN_CHANGE_PASSWORD: "admin/change_password",
  ADMIN_GET_ALL_JOBS: "job/getAll",
  ADMIN_POST_NEW_JOB: "job/create",
  ADMIN_PROFILE: "admin/admin_profile?"
};
