import axios from "@/services";

export default {
  async fetchCountries({ commit }) {
    try {
      const response = await axios.get("country/get/all");
      if (!response.data.error) {
        commit("setCountries", response.data.data);
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
