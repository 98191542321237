<template>
  <button
    v-on="$listeners"
    :class="buttonStyles"
    :disabled="disabled"
    :type="btnType"
  >
    <div class="w-5 flex flex__item-center" v-if="loading">
      <img src="../../assets/icons/ellipsis-loader.svg" alt="ellipsis-loader" />
    </div>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    theme: {
      type: String,
      default: "main"
    },
    mobile: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "df"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
      // validator: (value) => ["", "bordered"].includes(value),
    },
    btnType: {
      type: String,
      default: "submit"
    }
  },
  computed: {
    buttonStyles() {
      return [
        `button button__${this.theme} ${this.type}`,
        this.mobile ? "button__mobile" : "",
        `button__${this.size}`,
        this.disabled ? "button__disabled" : "",
        this.type ? this.type : ""
      ];
    }
  }
};
</script>

<style scoped lang="scss">
@forward "./button.scss";
</style>
