<template>
  <div id="app">
    <!-- <no-internet-dialog v-if="noInternet"></no-internet-dialog> -->

    <transition :name="animationName" mode="out-in">
      <router-view />
    </transition>
    <portal-target name="modal-portal" multiple></portal-target>
    <notifications />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import pageTransition from './mixins/pageTransition';
import noInternetMixin from './mixins/noInternet';
import NoInternetDialog from '@/components/NoInternetDialog';
import authService from './services/api/authService';
import { setPrivateHaders } from './services';

export default {
  name: 'App',
  mixins: [pageTransition, noInternetMixin],
  components: {
    NoInternetDialog
  },
  data: () => ({
    timer: 0
  }),
  computed: {
    ...mapGetters({
      userData: 'authnew/getUserData'
    })
  },
  methods: {
    ...mapMutations({
      setUserData: 'authnew/setUserData'
    }),
    refreshToken() {
      // this.timer = setTimeout(this.refreshToken, 10 * 1000);
      this.timer = setTimeout(this.refreshToken, 4 * 60 * 1000);
      if (!this.userData.token) {
        return;
      }

      authService
        .refreshToken({ refresh_token: this.userData.refreshToken })
        .then((res) => {
          this.setUserData(res.data);
          setPrivateHaders(res.data.token);
        })
        .catch()
        .finally(() => {});
    }
  },
  mounted() {
    this.refreshToken();
    const token = this.userData.token;
    if (!token) {
      return;
    }
    setPrivateHaders(token);
    // console.log(this.userData);
  }
};
</script>

<style lang="scss">
@use './assets/scss/index.scss' as *;

.vue-notification-group {
  z-index: 9999999 !important;
}

#app {
  @include query('mobile') {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
}
</style>
