export default {
  setUserData(state, payload) {
    if (!payload.refreshToken) {
      payload.refreshToken = state.userData.refreshToken;
    }
    state.userData = payload;
  },
  setProfileData(state, payload) {
    state.profileData = payload;
  },
  setProfile(state, payload) {
    state.profileData.profile = payload;
  },
  setUserRequiredAction(state, payload) {
    state.userData.user.requiredAction = payload;
  },
  // setProfileSummary(state, payload) {
  //   state.onboardingData.profileSummary = payload;
  // },
  setEducations(state, payload) {
    state.onboardingData.educations = payload;
  },
  setExperiences(state, payload) {
    state.onboardingData.experiences = payload;
  },
  setSocialNetworks(state, payload) {
    state.onboardingData.socialNetworks = payload;
  },
  setKeySkills(state, payload) {
    for (const property in payload) {
      state.onboardingData[property] = payload[property];
    }
  },

  setMyProfile(state, payload) {
    for (const property in payload) {
      state.onboardingData[property] = payload[property];
    }
  },

  logOutUser(state) {
    state.userData.token = "";
    location.href = "/job-seeker/login";
  }
};
