export default {
  userData: {},
  onboardingData: {
    fullName: "",
    phone: "",
    profileSummary: "",
    dateOfBirth: "",
    yearsOfExperience: "",
    position: "",
    gender: "",
    address: "",
    stateId: "",
    countryId: "",
    nyscStatus: "",
    languages: [],
    jobType: [],
    workType: [],
    skills: [],
    educations: [],
    experiences: [],
    socialNetworks: []
  },
  profileData: {}
};
