import instance from "../index.js";

export default {
  get(url) {
    return instance.get(url);
  },
  post(url, data = {}, config = {}) {
    return instance.post(url, data, config);
  },
  put(url, data) {
    return instance.put(url, data);
  },
  patch(url, data) {
    return instance.patch(url, data);
  },
  delete(url) {
    return instance.delete(url);
  },
};
