export default {
    data: () => ({
        noInternet: false,
    }),
    computed: {},
    methods: {
        handleNetworkChange() {
            window.addEventListener("online", () =>
                console.log("Became online")
            );
            window.addEventListener("offline", () =>
                console.log("Became offline")
            );
        },
    },
    mounted() {
        this.$nextTick(function () {
            window.addEventListener("online", () => {
                this.noInternet = false;
            });
            window.addEventListener("offline", () => {
                this.noInternet = true;
            });
        });

        if (navigator.onLine) {
            this.noInternet = false;
        } else {
            this.noInternet = true;
        }
    },
    watch: {
        noInternet(value) {
            if (value) {
                document.body.style.overflow = "hidden";
                return;
            }

            document.body.style.overflow = "auto";
        },
    },
};
